.Header {
  position: fixed;
  top: 0; /* Stick it to the top */
  max-height: 90px;
  width: 100vw;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: row;
  grid-template-areas: "logo nav";

  /* Cosmetics */
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.Logo {
  grid-area: logo;
  height: 65px;
}

.Nav {
  display: flex;
  grid-area: nav;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
  /* background-color: red; */
}
.Nav a {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  /* margin-left: 10%; */
  /* margin-right: 10%; */
}

.Nav a:hover {
  color: #faaf11;
}

.Nav button {
  display: none;
  padding: 10px;
  outline: none;
  border: none;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  background-color: #faaf11;
  border-radius: 4px;
  cursor: pointer;
  transition: 70ms;
}

.DesktopButton {
  /* display: block; */
  /* position:"absolute"; */
  /* right:"5%";
  top:"30%"; */
  background-color: "#FAAF11";
}

/* .Nav button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
} */

.Burger {
  display: none;
  grid-area: burger;
  margin: 0 20px 0 0;
  padding: 0;
  justify-self: end;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
}
.Burger:active {
  transform: scale(1.2);
}

/* .DesktopButton{
  display: block;
} */

@media (max-width: 960px) {
  .Header {
    grid-template-areas: "logo burger" "nav nav";
    display: grid;
  }
  .Nav {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;
    padding: 30px 0 20px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    /* background: rgba(40, 44, 47, 0.95); */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .Nav a {
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1rem;
  }

  .Nav Button {
    display: block;
  }

  .Logo {
    height: 45px;
  }

  .logoDiv {
    display: flex;
    align-items: center;
  }

  .DesktopButton {
    display: none;
    opacity: 0;
    visibility: none;
  }

  .Burger {
    display: inline;
    z-index: 1000;
  }

  .menuIcon {
    width: 35px;
    height: 35px;
  }
}

.NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}
