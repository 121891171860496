.singleTest {
  display: flex;
  padding: 8rem 3rem 1rem 3rem;
  gap: 5rem;
}

.testDescription {
  flex: 3;
}

.testDescription h1 {
  font-family: "Fredoka One", sans-serif;
  color: #faaf11;
  font-size: 2rem;
}

.testDescription p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: #5a5a5a;
  margin-top: 2rem;
}

.testDescription h3 {
  font-family: "Poppins", sans-serif;
  color: #faaf11;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 6rem;
}

.testDescription a {
  font-family: "Poppins", sans-serif;
  color: #faaf11;
  text-decoration: underline;
  font-size: 1.2rem;
}

.testContain {
  display: flex;
  justify-content: center;
}

.testDescription img {
  width: 700px;
}

.popularTestsTab {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.popularTestsTab h2 {
  font-family: "Poppins", sans-serif;
  color: #faaf11;
}

.popularSingleTest {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
}

.popularSingleTestDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.popularSingleTestDivImage {
  flex: 1;
  height: 100%;
  width: 100%;
}

.popularSingleTestDivText {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.popularSingleTestDivText h3 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #5a5a5a;
  font-size: 1rem;
}

.popularSingleTestDivText p {
  font-family: "Poppins", sans-serif;
  color: #5a5a5a;
  font-size: 0.8rem;
}

.popularSingleTestImage {
  width: 80px;
}

.kickOff {
  margin-top: 5rem;
}

.tradeMark p {
  font-size: 0.7rem;
}

.tradeMark {
  margin-top: 8rem;
}

@media only screen and (max-width: 480px) {
  .popularTestsTab {
    display: none;
  }

  .testDescription img {
    width: 300px;
  }

  .kickOff a {
    font-size: 1rem;
  }

  .tradeMark {
    margin-top: 6rem;
  }
}
