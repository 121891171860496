.containerMTT {
  padding: 5rem 0rem;
}

.contentMTT {
  height: 80vh;
  /* background-color: #00a3e3; */
  background-image: url("../assets/HowAreWeDiff.png");
  background-size: contain;
}

.Font1MTT h1 {
  font-size: 3rem;
  font-family: "Fredoka One";
  color: #faaf11;
  text-align: center;
}

.CardMTT {
  width: 50%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (max-width: 480px) {
  .Font1MTT h1 {
    font-size: 2rem;
  }

  .contentMTT {
    padding: 5rem 2rem;
    height: auto;
    background-image: url("../assets/HowAreWeDiff.png");
    background-size: cover;
  }

  .CardMTT {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-around;
  }
}
