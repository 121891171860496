@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
*{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
.container{
    min-height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#eee;
}
.container .card{
    height:530px;
    width:800px;
    background-color:#fff;
    position:relative;
    box-shadow:0 15px 30px rgba(0,0,0,0.1);
    font-family: 'Poppins', sans-serif;
}
.container .card .form{
    width:100%;
    height:100%;
    display:flex;
}
.container .card .left-side{
    width:50%;
    background-color:#fff;
    height:100%;
    overflow:hidden;
    
}
.left-side img{
    box-sizing:border-box;
    object-fit:cover;
    height:100%;
    width:100%;
}


.container .card .right-side{
    width:50%;
    background-color:#fff;
    height:100%;
    padding:20px;
    
}
.heading h3{
    letter-spacing:1px;
}
.heading p{
    margin-top:5px;
    font-size:12px;
    color:#898989;
    
    
}
.social{
    margin-top:20px;
    display:flex;
    flex-direction:column;
    gap:20px
}
.social span{
    height:40px;
    width:100%;
    border:1px solid #ccc;
    border-radius:7px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:12px;
    font-weight:600;
    cursor:pointer;
}
.social span i{
    font-size:13px;
    margin-right:4px;
    margin-top:2px;
    color:#0669e3;
}
.right-side hr{
    margin-top:20px;
}
.right-side p{
    
}
.or{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:-8px;
   
}
.or p{
     background-color:#fff;
    padding:0 4px;
    font-size:10px;
    font-weight:700;
}
.input-text{
    position:relative;
    margin-top:20px;
    width:100%;
}
input[type="text"]{
    height:45px;
    width:100%;
    border:none;
    border-radius:7px;
    background-color:#f5f5f5;
    outline:0;
    padding:0 10px;
    font-size:13px;
    padding-left:30px;
}
input[type="password"]{
    height:45px;
    width:100%;
    border:none;
    border-radius:7px; 
    background-color:#f5f5f5;
    outline:0; 
    padding:0 10px;
    font-size:13px;
    padding-left:30px;
    padding-right:30px;
}

.input-text label{
    position:absolute;
    left:30px;
    top:15px;
    font-size:12px;
    pointer-events:none;
    transition:all 0.5s;
}
.input-text i{
    position:absolute;
   
    top:17px;
    font-size:13px;
    cursor:pointer;
}

.input-text .fa-envelope{
    left:8px;
}
.input-text .fa-lock{
    position:absolute;
    left:8px;
    font-size:14px;
}
.input-text .fa-eye-slash{
    position:absolute;
    right:10px;
    
}
.input-text .fa-eye{
    position:absolute;
    right:10px;
}

.input-text input:focus~label,.input-text input:valid~label{
    top:2px;
    font-size:10px;
    font-weight:600;
}
.rem_pass{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:20px;
}
.remember{
    display:flex;
    align-items:center;
}
.remember span{
    height:25px;
    width:25px;
    border-radius:8px;
    border:1px solid #ccc;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#fff;
    cursor:pointer;
    transition:all 0.5s;
}
.remember p{
    font-size:12px;
    margin-left:5px;
    font-weight:700;
}
.rem_pass a{
    font-size:12px;
    color:blue;
    text-decoration:none;
    cursor:pointer;
}
.button{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:20px;
    
}
.button button{
    height:40px;
    width:100%;
    background-color:#ff805d;
    border:none;
    border-radius:8px;
    color:#fff;
    cursor:pointer;
    font-size:12px;
    transition:all 0.5s;
}
.button button:hover{
    background-color:#e33606;
}
.register{
    margin-top:30px;
    display:flex;
    justify-content:center;
}
.register p{
    font-size:10px;
    font-weight:700;
}
.register a{
    color:blue;
    text-decoration:none;
    cursor:pointer;
}

.warning{
    border:1px solid red !important;
}
.green{
    background-color:green !important;
}
.text-warning{
    border:1px solid red !important;
}


@media (max-width:750px) {
    .container .card {
        max-width: 350px;
        height:auto;
    }
    .container .card .right-side {
        width:100%;
       
    }
    .container .card .left-side {
        
        display: none;
    }
}