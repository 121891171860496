.containerWSUA {
  background-image: url("../assets/HowAreWeDiff.png");
  background-size: contain;
  height: auto;
  width: 100%;
  /* background-repeat: no-repeat; */
  color: white;
  text-align: center;
  margin-top: 1rem;
  padding: 8rem 4rem;
}

.headerWSUA h1 {
  font-family: "Fredoka one", sans-serif;
  color: white;
  font-size: 2rem;
}

.textWSUA {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.cardsWSUADiv {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5rem;
  height: 50%;
  /* background-color: red; */
}

.cardWSUA {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  padding-top: 1rem;
  width: 20%;
}

@media only screen and (max-width: 480px) {
  .containerWSUA {
    height: auto;
    background-image: url("../assets/HowAreWeDiff.png");
    background-size: cover;
  }

  .cardsWSUADiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    height: 100%;
    width: 100%;
  }

  .cardWSUA {
    height: 100%;
    width: 100%;
  }

  .cardImageWSUA {
    width: 100%;
  }
}
