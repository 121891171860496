.containerCU {
  background-image: url("../assets/ContactUsBanner.png");
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 18rem 5rem 5rem 5rem;
}

.bannerDivCU h1 {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: white;
}

.bannerDivCU h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: white;
}
