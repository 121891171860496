.popularTestsMainDiv {
  padding: 8rem 0;
}

.carousel {
  justify-content: center;

  /* left: 70%; */
}
.Font1 h1 {
  text-align: center;
  color: #faaf11;
  font-size: 3rem;
  font-family: "Fredoka One", sans-serif;
}
.Font1 {
  font-family: "Poppins", sans-serif;
}

.Font2 {
  font-family: "Poppins", sans-serif;
}

.Font2 p {
  text-align: center;
  color: #8b8c8c;
  font-size: 1.2rem;
  padding: 0 0.4rem;
}

.tests {
  width: 100%;
  padding: 0 5rem 0 5rem;
  /* background-color: red; */
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.carousel {
  display: none;
}

.allTestButton {
  display: flex;
  justify-content: center;
}

.allTestButton a {
  background-color: #faaf11;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 0.8rem 1.6rem;
  font-size: 1rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.allTestButtonMobile {
  display: none;
}

.cofounder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.viewMoreBtn {
  display: none;
}

@media only screen and (max-width: 480px) {
  .tests {
    display: none;
  }

  .allTestButton {
    display: none;
  }

  .allTestButtonMobile {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .allTestButtonMobile a {
    background-color: #faaf11;
    color: white;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-radius: 5px;
  }

  .Font1 h1 {
    text-align: center;
    color: #faaf11;
    font-size: 2rem;
  }

  .Font2 p {
    text-align: center;
    color: #8b8c8c;
    font-size: 1rem;
  }

  .carousel {
    display: block;
    margin-bottom: 2rem;
  }

  .cofounder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .viewMoreBtn {
    display: block;
    color: white;
    background-color: #faaf11;
    border: none;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    cursor: pointer;
  }
}
