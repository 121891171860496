.containerSG {
  padding: 10% 5% 0 5%;
}

.Font1 {
  flex: 1;
  padding: 0 5rem;
  color: #8b8c8c;
  font-family: "Poppins", sans-serif;
}

.welcomeHeader {
  text-align: center;
  margin-bottom: 5%;
  color: #faaf11;
  font-size: 40px;
  font-family: "Fredoka One", sans-serif;
  letter-spacing: 2px;
}

.content {
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
}

.welcomeImg {
  width: 500px;
  height: 350px;
  border-radius: 5px;
}

.Font1 p {
  font-size: 18px;
  /* margin-top: 1rem; */
}

.whereItBegan p {
  color: #8b8c8c;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
}

.whereItBegan2 {
  margin-top: 1rem;
}

.whereItBegan2 p {
  color: #8b8c8c;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
}

.knowMoreDiv {
  margin-top: 2rem;
}

.knowMore {
  align-self: center;
  color: white;
  background-color: #faaf11;
  border: none;
  padding: 20px 15px 20px 15px;
  border-radius: 10px;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.knowMore:hover {
  color: white;
}

.viewMoreBtn {
  display: none;
}

@media only screen and (max-width: 480px) {
  .welcomeImg {
    width: 350px;
    height: 250px;
    margin: 0 auto;
  }

  .containerSG {
    padding: 30% 0 0 0;
  }

  .whereItBegan {
    padding: 0 2rem;
  }

  .whereItBegan2 {
    padding: 0 2rem;
  }

  .viewMoreBtnDiv {
    padding: 0 2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
  }

  .Font1 {
    /* background-color: red; */
    width: 100%;
    /* flex: 1; */
    /* padding: 0 5rem; */
    padding: 0 10%;
    color: #8b8c8c;
    font-family: "Poppins", sans-serif;
  }

  .welcomeHeader {
    /* text-align: center; */
    width: 100%;
    margin-bottom: 10%;
    color: #faaf11;
    font-size: 2.5rem;
    font-family: "Fredoka One", sans-serif;
    letter-spacing: 1px;
  }

  .viewMoreBtn {
    display: block;
    color: white;
    background-color: #faaf11;
    border: none;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    cursor: pointer;
  }

  .knowMoreDiv {
    display: none;
  }
}
