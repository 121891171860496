.containerBanner {
  background-image: url("../assets/HomeBanner.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  padding: 18rem 5rem 5rem 5rem;
  /* padding: 0 20px 0 20px; */
  /* background-color: blue; */
}

.bannerDiv {
  display: flex;
  flex-direction: column;
}

.bannerDiv h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.2rem;
  color: white;
}

.bannerDiv h1 {
  font-family: "Poppins", sans-serif;
  font-size: 4rem;
  font-weight: bold;
  padding: 0;
  margin-bottom: 0;
  color: white;
  /* letter-spacing: 4px; */
}

.bannerDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.CTADiv {
  margin-top: 2rem;
}

.CTADiv a {
  color: white;
  background-color: #faaf11;
  padding: 20px 15px 20px 15px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  margin-top: 2rem;
}

.CTADiv a:hover {
  color: #faaf11;
  background-color: white;
}

@media only screen and (max-width: 480px) {
  .containerBanner {
    background-image: url("../assets/mobile.png");
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    padding: 13rem 2rem 5rem 2rem;
    text-align: center;
  }

  .bannerDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .bannerDiv h3 {
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  .bannerDiv h1 {
    font-family: "Poppins", sans-serif;
    font-size: 3rem;
    font-weight: bold;
    /* letter-spacing: 4px; */
  }

  .bannerDiv p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
  }
}
