html,
body {
  padding: 0;
  margin: 0;
  font-family: "Fredoka_One";
}

html,
body {
  scroll-behavior: smooth;
  scroll-snap-type: y;
}

html {
  overflow: auto;
}

@media (max-width: 300px) {
  html,
  body {
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    /* color: blue; */
  }
}

@media (max-width: 500px) {
  html,
  body {
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    /* color: blue; */
  }
}

@media (max-width: 700px) {
  html,
  body {
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    /* color: blue; */
  }
}

@media (max-width: 1000px) {
  html,
  body {
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    /* color: blue; */
  }
}

@media (max-width: 1660px) {
  html,
  body {
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    /* color: blue; */
  }
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
