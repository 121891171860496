.hiwbody {
  padding: 3rem 6rem;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.hiwbody p {
  line-height: 1.5;
}

.sun {
  position: absolute;
  width: 100px;
  top: 1%;
  left: 3%;
}

.book {
  position: absolute;
  width: 100px;
  top: 1%;
  right: 3%;
}

.rocket {
  position: absolute;
  width: 120px;
  top: 20%;
  left: 3%;
}

.earth {
  position: absolute;
  width: 120px;
  top: 40%;
  left: 45%;
}

.paintBrush {
  position: absolute;
  width: 120px;
  bottom: 15%;
  right: 10%;
}

.paperPlane {
  position: absolute;
  width: 120px;
  bottom: 1%;
  left: 10%;
}

.headerText {
  text-align: center;
  color: #8b8c8c;
  font-size: 1.4rem;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
}

.headerText h1 {
  color: #faaf11;
  font-family: "Fredoka One", sans-serif;
  font-size: 3rem;
  letter-spacing: 2px;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #8b8c8c; */
  width: 100%;
}

.illus1 img {
  bottom: 30%;
  z-index: 1;
}

.hiwbody .Font2 {
  font-family: "Poppins", sans-serif;
}

.detailsHeader {
  text-align: center;
  color: #faaf11;
  font-size: 2rem;
  font-family: "Fredoka One", sans-serif;
  display: flex;
  letter-spacing: 2px;
}

.textRight {
  text-align: center;
  color: #8b8c8c;
  font-family: "Poppins", sans-serif;
}

.textLeft {
  text-align: center;

  font-family: "Poppins", sans-serif;
}

.detailsHeaderRev {
  /* color: #6089CC; */
  font-size: 2rem;
  /* text-align: right; */
}

.detailsRev {
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: center;
  color: #8b8c8c;
}

.detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  height: 100%;
}

.detail p {
  line-height: 1.5;
}

.detailsCTA {
  background: none;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-width: 0.1rem;
  font-weight: 500;
  font-family: "Josefin Sans";
  text-decoration: none;
}

.knowMoreButtonLeft {
  align-items: flex-end;
}

.knowMoreBody {
  padding: 5rem 4rem 1rem 4rem;
}

.imageKid {
  width: 500px;
  height: 500px;
}

.programCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  height: 800px;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .hiwbody {
    padding: 3rem 1rem;
  }
  .details {
    display: block;
    /* background-color: #6089CC; */
  }
  .detailsRev {
    display: block;
  }

  .detailsHeader {
    color: #faaf11;
    font-size: 2rem;
    text-align: center;
  }

  .number {
    width: 75px;
    height: 75px;
  }

  .textRight {
    text-align: center;
  }

  .textLeft {
    text-align: center;
  }

  .detailsHeaderRev {
    color: #6089cc;
    font-size: 2rem;
    text-align: center;
  }

  .knowMoreButtonRight {
    align-items: center;
  }

  .knowMoreButtonLeft {
    align-items: center;
  }

  .imageKid {
    display: none;
  }

  .hiwbody .Font2 {
    font-family: "Poppins", sans-serif;
  }

  .headerText p {
    font-size: 1.2rem;
  }

  .programCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 2rem;
  }

  .sun {
    display: none;
  }

  .book {
    display: none;
  }

  .rocket {
    display: none;
  }

  .earth {
    display: none;
  }

  .paintBrush {
    display: none;
  }

  .paperPlane {
    display: none;
  }
}
