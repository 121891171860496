.termsAndCondBody {
  padding: 8rem 5rem 5rem 5rem;
}

.termsAndCondBody h1 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins";
}

.termsAndCondBody h2 {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: "Poppins";
}

.termsAndCondBody p {
  font-size: 1rem;
  font-family: "Poppins";
}

@media only screen and (max-width: 480px) {
  .termsAndCondBody {
    padding: 8rem 2rem 5rem 2rem;
  }
}
