.singleBlogBody{
    padding: 10rem 6rem 2rem 6rem;
    display: flex;
    gap: 5rem;
}

.blogDescription{
    flex: 3;
    padding: 0 3rem;
}

.blogDescription h1 {
    font-family: "Poppins", sans-serif;
    color: #faaf11;
    font-size: 3rem;
  }

.blogDescription p {
    font-family: "Poppins", sans-serif;
    color: #8b8c8c;
    font-size: 1.2rem;
    margin-top: 2rem;
  }

.blogDescription img {
    width: 700px;
    margin-top: 2rem;
  }

  .blogTwoImages{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .blogTwoImages img{
    width: 350px;
  }

  @media only screen and (max-width: 480px) {
    .singleBlogBody{
        padding: 10rem 2rem 2rem 2rem;
    }

    .blogDescription{
        padding: 0;
    }

    .blogTwoImages{
        display: flex;
        flex-direction: column;
    }

    .blogDescription img {
        width: 325px;
      }

      .blogDescription h1 {
        font-size: 2.6rem;
      }
  }