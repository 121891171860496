.resourcesBody {
  padding: 10rem 3rem 2rem 3rem;
}

.resourcesHeading {
  font-family: "Poppins", sans-serif;
  color: #faaf11;
  font-weight: bold;
  font-size: 2.8rem;
  text-align: center;
  margin: 0;
}

.resourcesSubHeading {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: #8b8c8c;
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 300;
}

.singleResource {
  display: flex;
  padding: 8rem 3rem;
  gap: 5rem;
}

.allResources {
  margin-top: 2rem;
}

.resourceDescription {
  flex: 3;
}

.resourceDetails {
  padding: 2rem 0;
}

.resourceDescription h1 {
  font-family: "Fredoka One", sans-serif;
  color: #faaf11;
  font-size: 2rem;
}

.resourceDescription p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: #8b8c8c;
  margin-top: 2rem;
}

.resourceDescription h3 {
  font-family: "Poppins", sans-serif;
  color: #faaf11;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1rem;
}

.resourceDate {
  display: flex;
  justify-content: flex-end;
}

.resourceDate p {
  font-size: 0.9rem;
}

.resourcesReadMoreBtn {
  align-self: center;
  color: white;
  background-color: #faaf11;
  border: none;
  padding: 15px 40px;
  border-radius: 10px;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1rem;
}

.popularResourceTab {
  flex: 1;
}

.popularResourceTab h2 {
  font-family: "Poppins";
  color: #faaf11;
}

.popularResource {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.popularResourcesDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.popularSingleResourceImage {
  width: 500px;
}

.popularSingleResourceVideo {
  width: 720px;
  height: 480px;
}

.popularResourceDivVideo {
  width: 100px;
  height: 100px;
}

.popularResourceDivImage {
  height: 100px;
}

@media only screen and (max-width: 480px) {
  .resourcesBody {
    padding: 10rem 1rem 2rem 1rem;
  }

  .singleResource {
    padding: 8rem 2rem;
  }

  .resourcesHeading {
    font-size: 3rem;
  }

  .resourcesSubHeading {
    font-size: 1rem;
  }

  .popularSingleResourceImage {
    width: 300px;
  }

  .popularResourceTab {
    display: none;
  }

  .popularSingleResourceVideo {
    width: 300px;
    height: 240px;
  }

  .resourceDate {
    justify-content: center;
  }
}
