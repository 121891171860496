.body {
  padding: 5rem 6rem 3rem 6rem;
  color: white;
  position: relative;
  z-index: 3;
  background-image: url("../assets/Footer.png");
  /* background-color: #00a3e3; */
  background-size: cover;
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
}

.subscribe {
  width: 45%;
}

.footerLogo {
  width: 150px;
}

.linksMain {
  display: flex;
  justify-content: space-around;
  letter-spacing: 2px;
  font-weight: 400;
  height: 100%;
  width: 100%;
}

.linksMain h3 {
  font-weight: 400;
  align-self: center;
  justify-content: center;
  display: flex;
  width: 100%;
  margin: auto;
  color: white;
  font-family: "Poppins", sans-serif;
}

.linksMain ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  height: 65%;
  width: 75%;
}

.linksMain ul li {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
}

.linksMain ul a {
  cursor: pointer;
  color: white;
  font-family: "Poppins", sans-serif;
}

.socialMedia {
  display: flex;
  width: 350px;
  justify-content: space-between;
  margin-top: 1rem;
}

.socialMedia img {
  width: 65px;
  height: 65px;
}

.copyright {
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  align-items: center;
}

.copyright p {
  font-family: "Poppins", sans-serif;
}

.privacy {
  display: flex;
  justify-content: space-around;
}

.privacy a {
  cursor: pointer;
}

.topArrow {
  position: absolute;
  bottom: 40%;
  right: 3%;
  cursor: pointer;
}

.linksCol {
  display: flex;
  flex-direction: column;
  /* justify-content: left; */
  /* background-color: aqua; */
  width: 100%;
  align-items: center;
}

.linksCol a:hover {
  color: #faaf11;
}

.linksCol h3 {
  color: #faaf11;
  font-weight: bold;
}

.linksCol a {
  margin-bottom: 0.6rem;
}

.address {
  display: flex;
  text-align: center;
  align-items: flex-start;
}

.address p {
  margin-bottom: 0.2rem;
}

.phone {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.mail {
  display: block;
}

.privacyPo {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.addressIcon {
  width: 20px;
}

.phoneIcon {
  width: 20px;
}

.mailIcon {
  width: 40px;
  margin-bottom: 0.8rem;
}

@media only screen and (max-width: 480px) {
  .body {
    height: 1200px;
    padding: 2rem 2rem 3rem 2rem;
    background-size: cover;
  }

  .content {
    margin-top: 1rem;
  }

  .linksMain {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
  }

  .linksMain ul {
    margin-top: 1rem;
  }

  .linksCol {
    width: 100%;
  }

  .linksCol h3 {
    color: #faaf11;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .footerLogo {
    height: 65px;
  }

  .topArrow {
    display: none;
  }

  .copyright {
    display: flex;
    flex-direction: column;
  }

  .subscribe {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }

  .mail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .mailIcon {
    width: 40px;
    margin-bottom: 1.4rem;
  }

  .address p {
    margin-bottom: 0.3rem;
  }

  .address {
    width: 100%;
  }

  .linksContact {
    margin-top: 2rem;
  }

  .socialMedia img {
    width: 60px;
    height: 60px;
  }
}
