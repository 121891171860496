.pagination {
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.pagination li {
}

.pagination a {
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border: 1px solid #faaf11;
  border-radius: 6px;
  color: #faaf11;
}

.pagination a:hover {
  color: white;
  background-color: #faaf11;
}

@media only screen and (max-width: 480px) {
  .pagination {
    list-style: none;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}
