.privacyBody {
  padding: 8rem 5rem 5rem 5rem;
}

.privacyBody h1 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins";
}

.privacyBody h2 {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: "Poppins";
}

.privacyBody p {
  font-size: 1rem;
  font-family: "Poppins";
}

@media only screen and (max-width: 480px) {
  .privacyBody {
    padding: 8rem 2rem 5rem 2rem;
  }
}
