.containerWeDoSomething {
  background-image: url("../assets/WeDoSomething.png");
  height: 100vh;
  width: 100vw;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.containerWeDoSomething h1 {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
}

.containerWeDoSomething a {
  background-color: #faaf11;
  color: white;
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  transition: all 0.5s ease;
}

.containerWeDoSomething a:hover {
  background-color: white;
  color: #faaf11;
}

.containerWeDoSomething p {
  display: none;
}

.mobileLine {
  display: none;
}

@media (max-width: 480px) {
  .containerWeDoSomething {
    background-image: url("../assets/HowAreWeDiff.png");
    display: flex;
    flex-direction: column;
    height: 70vh;
  }

  .containerWeDoSomething h1 {
    display: none;
  }

  .containerWeDoSomething p {
    display: block;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .containerWeDoSomething a {
    font-size: 0.8rem;
  }

  .mobileLine {
    display: block;
  }
}
