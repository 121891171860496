.containerSC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;
  padding: 10px 20px 50px 20px;
}

.containerSCDiv {
  flex: 1;
}

.callForm {
  flex: 2;
}

.containerSCImg {
  width: 500px;
  height: 500px;
}

.formHeader {
  color: #8b8c8c;
  padding: 8rem 0 0 0;
}

.formHeader h1 {
  font-family: "Fredoka one", sans-serif;
  color: #faaf11;
  font-size: 3rem;
  letter-spacing: 2px;
  text-align: center;
}

.formHeader p {
  font-family: "Poppins", sans-serif;
  color: #8b8c8c;
  font-size: 1rem;
  text-align: center;
}

.form form input {
  padding: 1.2rem;
  margin-bottom: 1rem;
  border: 1px solid black;
}

.form form input:focus {
  outline: none;
}

.form form input::placeholder {
  font-family: "Poppins";
  font-size: 1rem;
}

.form form button {
  background: none;
  background-color: #faaf11;
  color: white;
  padding: 1rem 2.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
  font-family: "Poppins";
  margin: 0;
}

.formControlSC {
  width: 85%;
}

.formThreeInputs {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  /* background-color: red; */
}

.formTwoInputs {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  /* background-color: red; */
}

.checkBox {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .containerSC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
  }

  .formControlSC {
    width: 100%;
  }

  /* .formcontrol {
    width: 10;
  } */

  .formHeader h1 {
    text-align: center;
    font-size: 2rem;
  }

  .formHeader p {
    text-align: center;
  }

  .callForm {
    width: 90%;
  }

  .formThreeInputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }

  .formTwoInputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }

  .scButton {
    display: flex;
    justify-content: center;
  }

  .containerSCImg {
    width: 350px;
    height: 350px;
    margin-bottom: 1rem;
  }

  .checkBox {
    display: flex;
    flex-direction: column;
  }
}
