.containerStats {
  position: relative;
  padding: 5% 0 5% 0;
}

/* Make the image responsive */
.containerStats img {
  width: 100%;
  height: auto;
}

/* Style the button and place it in the middle of the container/image */

.containerStats .div {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* padding: 10px 20px; */
  border: none;
  border-radius: 5px;
  /* font-size: 50px; */
  color: white;
  line-break: normal;
}

/* .div{
padding : 0px 10px;

  } */

/* @media only screen and (min-width: 320px) and (max-width: 580px){
    .div .stats{
    position: absolute;
    bottom: -50%;
    left: 0%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
   
    border: none;
    border-radius: 5px;
    
    color: white;
    line-break: normal;
  }
  } */
