.containerPNF {
  background-image: url("../assets/FileNotFound.png");
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 18rem 5rem 5rem 5rem;
}

.bannerDivPNF h1 {
  font-family: "Poppins", sans-serif;
  font-size: 4rem;
  font-weight: bold;
  padding: 0;
  margin-bottom: 0;
  color: white;
  /* letter-spacing: 4px; */
}

.bannerDivPNF p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.CTA {
  margin-top: 2rem;
}

.CTA a {
  color: white;
  background-color: #faaf11;
  padding: 20px 15px 20px 15px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  margin-top: 2rem;
}

.CTA a:hover {
  color: #faaf11;
  background-color: white;
}

@media only screen and (max-width: 480px) {
  .containerPNF {
    background-image: url("../assets/HomeBanner.png");
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    padding: 15rem 2rem 5rem 2rem;
    text-align: center;
  }

  .bannerDivPNF {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .bannerDivPNF h1 {
    font-family: "Poppins", sans-serif;
    font-size: 3rem;
    font-weight: bold;
    /* letter-spacing: 4px; */
  }

  .bannerDivPNF p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
  }
}
