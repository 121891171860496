.sidenav {
  width: 250px;
  transition: width 0.3s ease-in-out;
  height: 100%;
  background-color: rgb(10, 25, 41);
  padding-top: 28px;
}
.sidenavClosed {
  /* composes: sidenav; */
  height: 100%;
  background-color: rgb(10, 25, 41);
  transition: width 0.3s ease-in-out;
  width: 60px;
}
.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
  overflow: hidden;
}
.linkText {
  padding-left: 16px;
}

.sideitem:hover {
  background-color: #244f7d1c;
}
.menuBtn {
  align-self: center;
  align-self: flex-start;
  justify-self: flex-end;
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}
