.carouselDiv {
  background-color: #00a3e3;
}

.whatParentsDiv {
  background-image: url("../assets/HowAreWeDiff.png");
  background-size: contain;
  color: white;
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 8rem 4rem;
}

.whatParentsDiv h2{
  color: white;
  font-family: "Poppins";
  font-size: 2.5rem;
}

.whatParentsDiv h3{
  color: white;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 1.4rem;
  text-align: center;
  margin-top: 1rem;
}

.whatParentsDiv h4{
  color: white;
  margin-top: 2rem;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 1.6rem;
  text-align: center;
}

@media only screen and (max-width: 480px){
  .whatParentsDiv {
    background-image: url("../assets/HowAreWeDiff.png");
    background-size: cover;
    background-repeat: repeat;
    color: white;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 8rem 4rem;
  }

  .whatParentsDiv h2{
    color: white;
    font-family: "Poppins";
    font-size: 1.8rem;
    text-align: center;
  }

  .whatParentsDiv h3{
    color: white;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 1rem;
  }

  .whatParentsDiv h4{
    color: white;
    margin-top: 2rem;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 1.4rem;
    text-align: center;
  }
}